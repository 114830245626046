import { getLicensesCount } from "@src/app/licenseManager/api";
import { logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import { usCountyCode } from "@src/constants/phone";
import { logApiFailureEvent } from "@src/lib/analytics";
import { getFirebaseSingleton } from "@src/lib/firebase/src";
import { Agent } from "@src/lib/interface";
import firebase from "firebase";
import { enqueueSnackbar } from "notistack";
import { Dispatch } from "redux";

import {
  getReferralRate,
  requestAgentOTP,
  updateAgentLoggedInTime,
  validateAgentOTPv2,
} from "./api";
import { onLoggedOut, reloadFirebaseUser } from "./init";
import { ActionType, SessionAction } from "./session.model";
import { hcpAppLogger } from "../../remoteLoggers";
import { GetState } from "../store.model";

const firebaseAuth = getFirebaseSingleton();

function showErrorMessage(message: string) {
  enqueueSnackbar({
    variant: "error",
    message,
  });
}
/**
 * @description this function call the v3/requestOTP endpoint and doesn't expect any sign in token in return
 */
export const reVerifyPhoneRequestOTP =
  (phone: string) =>
  async (dispatch: Dispatch, getState: GetState): Promise<SessionAction | boolean> => {
    const { isSignup } = getState().session;

    const res = await requestAgentOTP(phone, isSignup);
    if (!isSignup) {
      if (!res || res?.error === "AgentNotFound") {
        dispatch({ type: ActionType.SET_IS_VERIFYING_DONE });
        showErrorMessage("No account found. New? Sign up today to join.");
        return false;
      }
    }
    if (res?.error) {
      hcpAppLogger({
        logType: "ERROR",
        title: res.error,
        logArea: "FIREBASE",
        featureName: "SIGNUP",
        hcpMobile: `${usCountyCode}${phone}`,
        details: {
          error: `${res.message}`,
        },
      });
      dispatch({ type: ActionType.SET_IS_VERIFYING_DONE });
      showErrorMessage("Unable to verify phone number. Please try again.");
      return false;
    }
    return dispatch({
      type: ActionType.VERIFICATION_ID_RECEIVED,
      data: {
        verification: { phone },
      },
    });
  };

/**
 * Only used for home/signupReVerify?phoneNumber=6086086088
 */
export const signUpReverifyWithOtp =
  (code: string, phone: string) =>
  async (dispatch: Dispatch, getState: GetState): Promise<SessionAction | boolean> => {
    dispatch({
      type: ActionType.VERIFICATION_REQUESTED,
    });
    const { isSignup } = getState().session;
    const res = await validateAgentOTPv2({ phone, code, isSignup });
    if (res?.error || !res?.data?.firebaseToken) {
      hcpAppLogger({
        logType: "ERROR",
        title: res.error,
        logArea: "FIREBASE",
        featureName: "LOGIN",
        hcpMobile: `${usCountyCode}${phone}`,
        details: {
          error: `${res.message}`,
          otp: code,
        },
      });
      dispatch({ type: ActionType.SET_IS_VERIFYING_DONE });
      logEvent(USER_EVENTS.TWO_FACTOR_CODE_DENIED);
      return false;
    }
    const firebaseToken = res?.data?.firebaseToken;
    try {
      await firebaseAuth.signInWithCustomToken(firebaseToken as string);
    } catch (error) {
      if (error) {
        hcpAppLogger({
          logType: "ERROR",
          title: "Firebase Authentication Failed",
          logArea: "FIREBASE",
          featureName: "LOGIN",
          hcpMobile: `${usCountyCode}${phone}`,
          details: {
            error: `${error}`,
            otp: code,
          },
        });
        dispatch({ type: ActionType.SET_IS_VERIFYING_DONE });
        showErrorMessage("Unable to Log In. Please try again.");
        logEvent(USER_EVENTS.TWO_FACTOR_CODE_DENIED);
        return false;
      }
    }

    logEvent(USER_EVENTS.TWO_FACTOR_CODE_ACCEPTED);
    // User and Agent does not exist in DB and user is currently in signup flow
    if (!isSignup) {
      updateAgentLoggedInTime(firebaseAuth);
    }
    logEvent(USER_EVENTS.SIGNED_IN);
    localStorage.removeItem("firebaseToken");
    return true;
  };

export const loginWithLink =
  (link: string, email: string) =>
  async (dispatch: Dispatch, getState: GetState): Promise<SessionAction | boolean> => {
    dispatch({
      type: ActionType.VERIFICATION_REQUESTED,
    });

    try {
      const { error } = await firebaseAuth.signInWithEmailLink(link, email);

      if (error) {
        dispatch({ type: ActionType.SET_IS_VERIFYING_DONE });
        showErrorMessage(error);
        return false;
      }
    } catch (error) {
      dispatch({ type: ActionType.SET_IS_VERIFYING_DONE });
      showErrorMessage("Error occurred while signing in, please try again.");
      return false;
    }

    updateAgentLoggedInTime(firebaseAuth);
    logEvent(USER_EVENTS.SIGNED_IN);
    return true;
  };

export const logout =
  (callback?: () => void) =>
  async (dispatch: Dispatch<any>, getState: GetState): Promise<void> => {
    dispatch({
      type: ActionType.LOGOUT_REQUESTED,
    });

    logEvent(USER_EVENTS.LOGGED_OUT);
    await firebaseAuth.signOut();
    callback?.();
  };

export const updateAgent = (agent: Agent) => ({
  type: ActionType.UPDATE_AGENT,
  data: { agent },
});

export const reloadAgentAuth =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    await reloadFirebaseUser(dispatch, firebaseAuth.currentUser as firebase.User);
  };

export const logOutAgentAuth =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    await onLoggedOut(dispatch);
  };

export const reloadAgentProfile =
  (logError = true) =>
  async (): Promise<void> => {
    try {
      await firebaseAuth.currentUser?.getIdTokenResult(true);
    } catch (error) {
      if (logError) {
        const err = error as Error;
        logEvent(USER_EVENTS.ONBOARDING_ERROR, {
          message: "Unable to re-load agent profile",
          error: err?.message,
        });
      }
    }
  };

/**
 * @deprecated - remove this usage
 */
export const setIsSignup = (dispatch: Dispatch): void => {
  localStorage.setItem("isSignup", "true");
  dispatch({
    type: ActionType.SET_IS_SIGNUP,
  });
};

/**
 * @deprecated Review this usage.
 */
export const setReferralRate =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    const referralRate = await getReferralRate();
    dispatch({
      type: ActionType.UPDATE_REFERRAL_RATE,
      data: { referralRate },
    });
  };

export const setLicensesCount =
  (workerId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const licensesCount = await getLicensesCount(workerId);
      dispatch({
        type: ActionType.UPDATE_AGENT,
        data: {
          agent: { licensesCount },
        },
      });
    } catch (err) {
      logApiFailureEvent(err);
    }
  };

export const setRecognizedHoliday = (recognizedHoliday: string) => ({
  type: ActionType.SET_RECOGNIZED_HOLIDAY,
  data: {
    recognizedHoliday,
  },
});
