import { App, AppState } from "@capacitor/app";
import { Preferences } from "@capacitor/preferences";
import { CbhSegment } from "@clipboard-health/cbh-segment";
import { isPlatform } from "@ionic/react";
import { getLicensesCount } from "@src/app/licenseManager/api";
import { environmentConfig, isTestNodeEnvironment } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS, getAppInfo } from "@src/appV2/lib";
import { logError, logEvent } from "@src/appV2/lib/analytics";
import { UXCamSdk } from "@src/appV2/lib/UXCamSdk/UXCamSdk";
import { USER_EVENTS } from "@src/constants";
import { createSegmentInstance, identifyUser, logApiFailureEvent } from "@src/lib/analytics";
import { getFirebaseSingleton } from "@src/lib/firebase";
import { Agent } from "@src/lib/interface/src";
import { ZendeskSDK } from "capacitor-zendesk-sdk";
import firebase from "firebase";
import moment from "moment-timezone";
import { enqueueSnackbar } from "notistack";
import { Dispatch } from "redux";
import request from "superagent";

import { getUserByEmail } from "./api";
import { fetchEnvVariables, updateAgentData } from "./api";
import { ActionType, LocalStorage } from "./session.model";
import { deprecatedDoNotUseLogError } from "../../../appV2/lib/analytics/log";
import { getZendeskJWTMessagingSDK } from "../../api/zendesk";
import { clearDatadogRumUser, identifyDatadogRumUser } from "../../utils/datadog";
import { ActionType as SpecialityAction } from "../specialities/model";

async function initAnalytics(): Promise<void> {
  createSegmentInstance({
    key: environmentConfig.REACT_APP_SEGMENT_KEY,
    androidKey: environmentConfig.REACT_APP_SEGMENT_ANDROID_KEY,
    iosKey: environmentConfig.REACT_APP_SEGMENT_IOS_KEY,
    webKey: environmentConfig.REACT_APP_SEGMENT_WEB_KEY,
  });

  UXCamSdk.optIntoSchematicRecordings(); //To enable session video recording on iOS

  const configuration = {
    userAppKey: environmentConfig.REACT_APP_UX_CAM_KEY,
    enableAutomaticScreenNameTagging: true,
    enableImprovedScreenCapture: true,
  };
  UXCamSdk.startWithConfiguration(configuration);
}

const IS_TEST_ENV = isTestNodeEnvironment();
if (!IS_TEST_ENV) {
  initAnalytics();
}

export const initSession =
  (pathname: string, email: string) =>
  (dispatch: Dispatch): void => {
    loadCustomFlags(dispatch);
    initFirebaseListeners(dispatch, pathname, email);
    initAppState(dispatch);
    setEnvVariables(dispatch);
    logEvent(USER_EVENTS.APP_LAUNCHED);
  };

const loadCustomFlags = (dispatch): void => {
  const isSignupFlag = localStorage.getItem("isSignup");
  if (isSignupFlag) {
    dispatch({
      type: ActionType.SET_IS_SIGNUP,
    });
  }
};

const initFirebaseListeners = (dispatch: Dispatch, pathname: string, email: string): void => {
  if (pathname === "/welcome/login/emailVerify" && !email) {
    localStorage.setItem("isInitAutoLogin", "true");
  }
  const authChangeListener = onAuthChanged(dispatch, pathname, email);
  getFirebaseSingleton().onAuthStateChanged(authChangeListener);
  getFirebaseSingleton().onIdTokenChanged(authChangeListener);
};

const initAppState = (dispatch: Dispatch): void => {
  const onStateChange = (state: AppState): void => {
    if (!state.isActive) {
      return;
    }

    const token = localStorage.getItem(LocalStorage.AUTH_TOKEN);
    if (token) {
      offlineStoreAgent(dispatch);
    }
    logEvent(USER_EVENTS.APP_RESTORED);
  };

  App.addListener("appStateChange", onStateChange);
};

const setEnvVariables = async (dispatch: Dispatch): Promise<void> => {
  const data = await fetchEnvVariables();
  dispatch({
    type: ActionType.SET_ENV_VARIABLES,
    data,
  });
};

const onLoggedIn = async (
  dispatch: Dispatch,
  user: firebase.User,
  forceRefresh?: boolean
): Promise<void> => {
  if (!user) {
    return;
  }
  try {
    const IdTokenResult = await user.getIdTokenResult(forceRefresh);
    const { token, claims } = IdTokenResult;
    const userAgent = await getUserByEmail(token);
    const { agent } = userAgent || {};
    if ((user.email && agent) || claims?.syncEmail) {
      initApp(token, userAgent.tmz);
      let value;
      try {
        value = await fetchAgent(dispatch, token);
      } catch (error) {
        logEvent(USER_EVENTS.ONBOARDING_ERROR, {
          message: "Error fetching agent onLoggedIn",
          error: error?.message,
        });
      }
      value ? offlineStoreAgent(dispatch) : await offlineStoreAgent(dispatch);
      dispatch({
        type: ActionType.LOGGED_IN,
        data: {
          userId: userAgent._id,
          sendBirdAccessToken: userAgent.sendBirdAccessToken,
          profile: {
            name: agent.name,
            email: agent.email,
            phone: agent.phone,
          },
        },
      });
    } else {
      // Only phone number entered by new Agent that has started Signup flow
      initApp(token);
      const value = await fetchAgent(dispatch, token);
      value ? offlineStoreAgent(dispatch) : await offlineStoreAgent(dispatch);
      dispatch({
        type: ActionType.LOGGED_IN,
        data: {
          userId: undefined,
          sendBirdAccessToken: undefined,
          profile: {
            name: undefined,
            email: undefined,
            phone: user.phoneNumber,
          },
        },
      });
    }
    agent && saveAgentStageandMSAInfo(agent);
    loginZendesk();
    setEnvVariables(dispatch);
  } catch {
    onLoggedOut(dispatch);
  }
};

const saveAgentStageandMSAInfo = (agent: Agent): void => {
  localStorage.setItem("agentstage", agent?.stage ?? "");
  localStorage.setItem("agentmsa", agent?.address?.metropolitanStatisticalArea ?? "");
  localStorage.setItem("workerUserId", agent?.userId ?? "");
};

export const initApp = (token, tmz?: string): void => {
  if (token) {
    localStorage.setItem(LocalStorage.AUTH_TOKEN, token);
  }
  if (tmz) {
    moment.tz.setDefault(tmz);
  }
};

export const onLoggedOut = (dispatch: Dispatch): void => {
  dispatch({
    type: ActionType.LOGGED_OUT,
  });
  localStorage.removeItem(LocalStorage.AUTH_TOKEN);
  localStorage.removeItem(LocalStorage.RECOGNIZED_HOLIDAYS);
  localStorage.removeItem("claimCheck");
  localStorage.removeItem("isSignup");
  Preferences.clear();
  UXCamSdk.stopSessionAndUploadData();
  clearDatadogRumUser();
};

const onAuthChanged = (dispatch: Dispatch, pathname: string, email: string) => {
  let inProcess = false;
  let timeout: ReturnType<typeof setTimeout> | null;
  return async (user: firebase.User | null) => {
    if (inProcess) {
      return;
    }
    inProcess = true;
    if (timeout) {
      clearTimeout(timeout);
    }
    const isInitAutoLogin = localStorage.getItem("isInitAutoLogin");
    if (isInitAutoLogin === "true" && user && pathname === "/welcome/login/emailVerify" && !email) {
      // this will return in signin page, and not automatically login
      getFirebaseSingleton()
        .signOut()
        .then(() => {
          inProcess = false;
          indexedDB.deleteDatabase("firebaseLocalStorage");
        });
    } else if (user) {
      await onLoggedIn(dispatch, user);
      inProcess = false;
    } else {
      timeout = setTimeout(() => {
        onLoggedOut(dispatch);
        timeout = null;
      }, 1000);
      inProcess = false;
    }
    localStorage.removeItem("isInitAutoLogin");
  };
};

export const reloadFirebaseUser = async (
  dispatch: Dispatch,
  user: firebase.User
): Promise<void> => {
  dispatch({
    type: ActionType.CHECKING_AUTHORIZATION,
  });
  await onLoggedIn(dispatch, user, true);
};

const fetchAgent = async (dispatch: Dispatch, token: string): Promise<boolean | undefined> => {
  const { value } = await Preferences.get({ key: LocalStorage.AGENT });
  const { otaBuildId, version } = await getAppInfo();

  if (!value) {
    return false;
  }
  const agent = JSON.parse(value);
  if (agent) {
    if (agent && agent.isFirstSession) {
      if (agent.isIdentifiedInSegment !== true) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let cbhSegment: any = "";
        cbhSegment = new CbhSegment(environmentConfig.REACT_APP_SEGMENT_KEY as string);

        cbhSegment.identify({
          userId: agent.userId.toString(),
          traits: {
            createdAt: agent.createdAt,
            email: agent.email,
            name: agent.name,
            phone: agent.phone,
            type: "Agent",
            version,
            otaBuildId,
          },
        });
      }

      await updateAgentData(token, {
        agentId: agent.userId,
        isFirstSession: false,
        isIdentifiedInSegment: true,
      });
    }

    UXCamSdk.setUserIdentity(agent.name ?? agent.userId ?? "ANONYMOUS");

    UXCamSdk.setUserProperties({
      user_id: agent.userId,
      alias: agent.name,
      email: agent.email,
      qualification: agent.preference?.qualification,
      otaBuildId,
    });

    identifyDatadogRumUser(agent);
    dispatch({
      type: ActionType.AGENT_PROFILE,
      data: { agent },
    });
    dispatch({
      type: SpecialityAction.GET_SPECIALITIES_DATA,
      data: {
        experienceDetails: agent?.specialities?.experienceDetails ?? {},
        hasSedationExperience: agent?.specialities?.hasSedationExperience ?? false,
        hasTrayAssemblyExperience: agent?.specialities?.hasTrayAssemblyExperience ?? false,
        experience: agent?.specialities?.experience ?? [],
      },
    });
    identifyUser(agent);
    return true;
  }
};

async function updateUserSession(): Promise<string | undefined> {
  if (!getFirebaseSingleton().currentUser) {
    return;
  }
  const IdTokenResult = await getFirebaseSingleton().currentUser?.getIdTokenResult();
  initApp(IdTokenResult?.token);
}

const setLicensesCount = async (dispatch: Dispatch, workerId: string): Promise<void> => {
  try {
    const licensesCount = await getLicensesCount(workerId);
    dispatch({
      type: ActionType.UPDATE_AGENT,
      data: {
        agent: { licensesCount },
      },
    });
  } catch (err) {
    logApiFailureEvent(err);
  }
};

const showAgentProfileFailedToLoadError = (dispatch: Dispatch) => {
  enqueueSnackbar({
    message: "Error occurred while fetching worker details",
    color: "error",
  });
  dispatch({
    type: ActionType.SHOW_FETCH_WORKER_FAILURE_ALERT,
    data: {
      isFetchAgentProfileFailureAlertEnabled: true,
    },
  });
};

const offlineStoreAgent = async (dispatch: Dispatch): Promise<void> => {
  await updateUserSession();
  const token = localStorage.getItem(LocalStorage.AUTH_TOKEN) as string;
  let agent;
  try {
    const { body } = await request
      .get(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile`)
      .retry(3, (error) => {
        if (!error) {
          return false;
        }
        deprecatedDoNotUseLogError({
          message: `Failed to load agent profile. ${JSON.stringify(error?.stack || error)}`,
          metadata: {
            email: getFirebaseSingleton().currentUser?.email,
          },
        });
        return true;
      })
      .set("Authorization", token);
    agent = body;
    dispatch({
      type: ActionType.SHOW_FETCH_WORKER_FAILURE_ALERT,
      data: {
        isFetchAgentProfileFailureAlertEnabled: false,
      },
    });
  } catch (error) {
    logApiFailureEvent(error);
    showAgentProfileFailedToLoadError(dispatch);
  }

  if (!agent) {
    return;
  }

  try {
    await Preferences.set({
      key: LocalStorage.AGENT,
      value: JSON.stringify(agent),
    });
    dispatch({
      type: ActionType.AGENT_PROFILE,
      data: { agent },
    });
    setLicensesCount(dispatch, agent.userId);
    fetchAgent(dispatch, token);
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const loginZendesk = async (): Promise<void> => {
  if (!isPlatform("capacitor")) {
    return;
  }

  try {
    const token = await getZendeskJWTMessagingSDK();
    await ZendeskSDK.loginUser({ token });
  } catch (error) {
    logError(APP_V2_APP_EVENTS.ZENDESK_LOGIN_ERROR, { error });
  }
};
